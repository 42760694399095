<template>
<v-row justify="center" style="margin-bottom:100px;">

   <v-data-iterator
      :items="imageCardLists"
      :items-per-page.sync="itemsPerPage"
      :page.sync="page"
      :search="search"
      :sort-by="sortBy.toLowerCase()"
      :sort-desc="sortDesc"
      hide-default-footer
    >
      <template v-slot:header>
        <v-toolbar
          class="mb-1"
        >
          <v-text-field
            v-model="search"
            clearable
            flat
            solo-inverted
            hide-details
            prepend-inner-icon="mdi-magnify"
            label="Search"
          ></v-text-field>
          <template v-if="$vuetify.breakpoint.mdAndUp">
            <v-spacer></v-spacer>
            <span>タイトルに含まれる文字列で絞り込めます</span>
          </template>
        </v-toolbar>
      </template>

      <template v-slot:default="props">
        <v-row class="mt-8">
          <v-col
            v-for="item in props.items"
            :key="item.name"
            cols="12"
            xs="12"
            sm="6"
            md="4"
            lg="3"
          >
            <image-card
              :title="item.title"
              :img_url="item.img_url"
              :caption="item.caption"
              :adb_url="item.adb_url"
              :px_url="item.px_url"
              :ss_url="item.ss_url"
              :imgm_url="item.imgm_url"
              :istk_url="item.istk_url"
              :tag_type="item.tag_type"
              :tag_cathegory="item.tag_cathegory"
          />
          </v-col>
        </v-row>
      </template>

      <template v-slot:footer>
        <v-row
          class="mt-2"
          align="center"
          justify="center"
          style="padding:0 30px;"
        >
          <span class="grey--text">１ページの最大表示数</span>
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                dark
                text
                color="primary"
                class="ml-2"
                v-bind="attrs"
                v-on="on"
              >
                {{ itemsPerPage }}枚
                <v-icon>mdi-chevron-down</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                v-for="(number, index) in itemsPerPageArray"
                :key="index"
                @click="updateItemsPerPage(number)"
              >
                <v-list-item-title>{{ number }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>

          <v-spacer></v-spacer>

          <span
            class="mr-4
            grey--text"
          >
            Page {{ page }} of {{ numberOfPages }}
          </span>
          <v-btn
            fab
            dark
            color="primary"
            class="mr-1"
            small
            @click="formerPage"
          >
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
          <v-btn
            fab
            dark
            color="primary"
            class="ml-1"
            small
            @click="nextPage"
          >
            <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
        </v-row>
      </template>
    </v-data-iterator>
</v-row>
</template>

<script>

          /* 各サイト素材一覧＆プロフィールページ */
        const common_px_url = 'https://pixta.jp/f/intaf/@interemit';
        const common_ss_url = 'https://www.shutterstock.com/g/interemit?rid=266042052';
        const common_imgm_url = 'https://imagemart.aflo.com/ja/@interemit';
        const common_istk_url = 'https://www.istockphoto.com/jp/portfolio/interemit';

  export default {
    name: 'works_stockillustration',
    components: {

    },
    data(){
      return{itemsPerPageArray: [20, 40, 60],
        search: '',
        filter: {},
        sortDesc: false,
        page: 1,
        itemsPerPage: 20,
        sortBy: 'name',
        keys: [
          '鳥',
          '人物',
          '植物',
          '景色',
          '春',
          '夏',
          '秋',
          '冬',
        ],
        imageCardLists: [
          {
            title:'かわいい街並み',
            img_url:'/img/min/houses_real_rough02th_.png',
            caption: 'いろんなタイプの住宅',
            adb_url:'https://stock.adobe.com/jp/images/1-4/481486877?prev_url=detail',
            px_url: common_px_url,
            ss_url: common_ss_url,
            imgm_url: common_imgm_url,
            istk_url: common_istk_url,
            tag_type:{
              vector: true,
              raster: true,
            },
            tag_cathegory: ['家', '風景'],
          },
          {
            title:'本の上に座る女の子',
            img_url:'/img/min/girl210903_2th_.png',
            caption: '※イメージです',
            adb_url:'https://stock.adobe.com/jp/images/1-4/454833811?prev_url=detail',
            px_url: common_px_url,
            ss_url: common_ss_url,
            imgm_url: common_imgm_url,
            istk_url: common_istk_url,
            tag_type:{
              vector: true,
              raster: true,
            },
            tag_cathegory: ['女子', '読書', '人物'],
          },
          {
            title:'飛ぶ女の子',
            img_url:'/img/min/isome_girl06th_.png',
            caption: '軽やかにステップ',
            adb_url:'https://stock.adobe.com/jp/images/1-4/440663501?prev_url=detail',
            px_url: common_px_url,
            ss_url: common_ss_url,
            imgm_url: common_imgm_url,
            istk_url: common_istk_url,
            tag_type:{
              vector: true,
              raster: true,
            },
            tag_cathegory: ['女子', '仕草', '人物'],
          },
          {
            title:'夏らしい貝殻と地図',
            img_url:'/img/min/map_summer_02th_.png',
            caption: 'サマーキャンペーン用',
            adb_url:'https://stock.adobe.com/jp/images/1-4/503835104?prev_url=detail',
            px_url: common_px_url,
            ss_url: common_ss_url,
            imgm_url: common_imgm_url,
            istk_url: common_istk_url,
            tag_type:{
              vector: true,
              raster: true,
            },
            tag_cathegory: ['夏', '自然', 'パーツ'],
          },
          {
            title:'ふわふわのぬいぐるみのようなうさぎ',
            img_url:'/img/min/bg_red_conte_rabbitth_.png',
            caption: '描いている時にふわふわにするのが楽しいです',
            adb_url:'https://stock.adobe.com/jp/images/1-4/492505525?prev_url=detail',
            px_url: common_px_url,
            ss_url: common_ss_url,
            imgm_url: common_imgm_url,
            istk_url: common_istk_url,
            tag_type:{
              vector: true,
              raster: true,
            },
            tag_cathegory: ['うさぎ', '干支', '年賀'],
          },
          {
            title:'卯年年賀状 うさぎの輪',
            img_url:'/img/min/nenga2023_8th_.png',
            caption: '卯年年賀状',
            adb_url:'https://stock.adobe.com/jp/images/1-4/492384673?prev_url=detail',
            px_url: common_px_url,
            ss_url: common_ss_url,
            imgm_url: common_imgm_url,
            istk_url: common_istk_url,
            tag_type:{
              vector: true,
              raster: true,
            },
            tag_cathegory: ['うさぎ', '干支', '年賀'],
          },
          {
            title:'走るうさぎ',
            img_url:'/img/min/usagi_02_3th_.png',
            caption: '年賀状用パーツ',
            adb_url:'https://stock.adobe.com/jp/images/1-4/523103365?prev_url=detail',
            px_url: common_px_url,
            ss_url: common_ss_url,
            imgm_url: common_imgm_url,
            istk_url: common_istk_url,
            tag_type:{
              vector: true,
              raster: true,
            },
            tag_cathegory: ['うさぎ', '干支', '年賀'],
          },
          {
            title:'ゆるうさぎ',
            img_url:'/img/min/rabbits_07th_.png',
            caption: '夫婦でゆるっと',
            adb_url:'https://stock.adobe.com/jp/images/1-4/489650713?prev_url=detail',
            px_url: common_px_url,
            ss_url: common_ss_url,
            imgm_url: common_imgm_url,
            istk_url: common_istk_url,
            tag_type:{
              vector: true,
              raster: true,
            },
            tag_cathegory: ['うさぎ', '干支', '年賀'],
          },
          {
            title:'トナカイ',
            img_url:'/img/min/tonakai03th_.png',
            caption: 'クリスマスカードに。',
            adb_url:'https://stock.adobe.com/jp/images/1-4/459458684?prev_url=detail',
            px_url: common_px_url,
            ss_url: common_ss_url,
            imgm_url: common_imgm_url,
            istk_url: common_istk_url,
            tag_type:{
              vector: true,
              raster: true,
            },
            tag_cathegory: ['動物', 'クリスマス', '冬'],
          },
          {
            title:'桜の花弁が水面に浮かぶ',
            img_url:'/img/min/250px_sakura_hanaikada02.png',
            caption: '華やかな花筏のパース付き背景ベクターイラスト',
            adb_url:'https://stock.adobe.com/jp/images/1-4/402508244?prev_url=detail',
            px_url: common_px_url,
            ss_url: common_ss_url,
            imgm_url: common_imgm_url,
            istk_url: common_istk_url,
            tag_type:{
              vector: true,
              raster: true,
            },
            tag_cathegory: ['植物', '自然', '春'],
          },



          {
            title:'本を持つ女の子',
            img_url:'/img/min/250px_Reader02.png',
            caption: 'このシリーズで最初に書いた絵。レトロな風合いを目指しました。',
            adb_url:'https://stock.adobe.com/jp/images/1-4/426316521?prev_url=detail',
            px_url: common_px_url,
            ss_url: common_ss_url,
            imgm_url: common_imgm_url,
            istk_url: common_istk_url,
            tag_type:{
              vector: true,
              raster: true,
            },
            tag_cathegory: ['女子', '読書', '人物'],
          },
          {
            title:'本を開く女の子',
            img_url:'/img/min/250px_reader_03.png',
            caption: 'このシリーズは国を問わず売れる印象です。',
            adb_url:'https://stock.adobe.com/jp/images/%E5%8B%89%E5%BC%B7%E3%81%99%E3%82%8B%E8%8B%A5%E3%81%84%E5%A5%B3%E6%80%A7%E3%81%AE%E3%82%B9%E3%82%BF%E3%82%A4%E3%83%AA%E3%83%83%E3%82%B7%E3%83%A5%E3%81%AA%E3%83%99%E3%82%AF%E3%82%BF%E3%83%BC%E3%82%A4%E3%83%A9%E3%82%B9%E3%83%88/439208595?prev_url=detail',
            px_url: common_px_url,
            ss_url: common_ss_url,
            imgm_url: common_imgm_url,
            istk_url: common_istk_url,
            tag_type:{
              vector: true,
              raster: true,
            },
            tag_cathegory: ['女子', '読書', '人物'],
          },
          {
            title:'キャンバスに向かう女子のラフなタッチのイラスト',
            img_url:'/img/min/250px_artist01.png',
            caption: '女の子イラストシリーズ増やしていきたいです',
            adb_url:'https://stock.adobe.com/jp/images/%E7%B5%B5%E3%82%92%E6%8F%8F%E3%81%8F%E8%8A%B8%E8%A1%93%E7%B3%BB%E5%A5%B3%E5%AD%90%E3%81%AE%E3%82%B9%E3%82%BF%E3%82%A4%E3%83%AA%E3%83%83%E3%82%B7%E3%83%A5%E3%81%AA%E3%83%99%E3%82%AF%E3%82%BF%E3%83%BC%E3%82%A4%E3%83%A9%E3%82%B9%E3%83%88/440511867?prev_url=detail',
            px_url: common_px_url,
            ss_url: common_ss_url,
            imgm_url: common_imgm_url,
            istk_url: common_istk_url,
            tag_type:{
              vector: true,
              raster: true,
            },
            tag_cathegory: ['女子', '人物', '芸術'],
          },
          {
            title:'アジャイル開発とウォーターフォール開発',
            img_url:'/img/min/250px_pc_desk_c4.png',
            caption: '私が関わらせていただいているプロジェクトはアジャイル型が多いです。',
            adb_url:'https://stock.adobe.com/jp/images/1-4/426316521?prev_url=detail',
            px_url: common_px_url,
            ss_url: common_ss_url,
            imgm_url: common_imgm_url,
            istk_url: common_istk_url,
            tag_type:{
              vector: true,
              raster: true,
            },
            tag_cathegory: ['アイソメ', 'IT', '人'],
          },
          {
            title:'ITの現場で働くひとたち',
            img_url:'/img/min/250px_pc_desk_c1.png',
            caption: 'アイソメトリック絵柄はとくにIT系が人気です',
            adb_url:'https://stock.adobe.com/jp/images/1-4/426316521?prev_url=detail',
            px_url: common_px_url,
            ss_url: common_ss_url,
            imgm_url: common_imgm_url,
            istk_url: common_istk_url,
            tag_type:{
              vector: true,
              raster: true,
            },
            tag_cathegory: ['WEB', 'IT', 'アイソメ'],
          },
          {
            title:'クレヨン風 寝転ぶ虎の子の正月ラベル',
            img_url:'/img/min/250px_nenga2022_26.png',
            caption: 'お気に入りです。',
            adb_url:'https://stock.adobe.com/jp/images/2022/467855014?prev_url=detail',
            px_url: common_px_url,
            ss_url: common_ss_url,
            imgm_url: common_imgm_url,
            istk_url: common_istk_url,
            tag_type:{
              vector: true,
              raster: true,
            },
            tag_cathegory: ['お気に入り', '虎', '年賀状'],
          },
          {
            title:'シンプルな虎の親子の年賀状',
            img_url:'/img/min/250px_nenga2022_20_2.png',
            caption: '幾何学図形がポイント。とくに国内向けで人気でした',
            adb_url:'https://stock.adobe.com/jp/images/%E7%AB%B9%E3%81%AB%E8%99%8E%E3%80%81%E5%AF%8C%E5%A3%AB%E5%B1%B1%E3%81%AE%E3%82%B7%E3%83%B3%E3%83%97%E3%83%AB%E3%81%8B%E3%82%8F%E3%81%84%E3%81%84%E5%B9%B4%E8%B3%80%E7%8A%B6%E3%83%86%E3%83%B3%E3%83%97%E3%83%AC%E3%83%BC%E3%83%88-%E3%83%99%E3%82%AF%E3%82%BF%E3%83%BC%E3%82%A4%E3%83%A9%E3%82%B9%E3%83%88-%E6%A8%AA%E4%BD%8D%E7%BD%AE/449638734?prev_url=detail',
            px_url: common_px_url,
            ss_url: common_ss_url,
            imgm_url: common_imgm_url,
            istk_url: common_istk_url,
            tag_type:{
              vector: true,
              raster: true,
            },
            tag_cathegory: ['年賀状', '虎', '親子'],
          },
          {
            title:'晴着の男の子女の子年賀状イラスト',
            img_url:'/img/min/250px_nenga2022_19_yoko.png',
            caption: '着物の柄はもっとバリエーションを増やしたいと思っています',
            adb_url:'https://stock.adobe.com/jp/images/2022-4/446889159?prev_url=detail',
            px_url: common_px_url,
            ss_url: common_ss_url,
            imgm_url: common_imgm_url,
            istk_url: common_istk_url,
            tag_type:{
              vector: true,
              raster: true,
            },
            tag_cathegory: ['年賀状', '人', ''],
          },
          {
            title:'親子虎の年賀状イラスト',
            img_url:'/img/min/250px_nenga2022_12_tate.png',
            caption: '寅年年賀状で一番売れたのはこの親子虎sです。',
            adb_url:'https://stock.adobe.com/jp/images/2022-4/444631551?prev_url=detail',
            px_url: common_px_url,
            ss_url: common_ss_url,
            imgm_url: common_imgm_url,
            istk_url: common_istk_url,
            tag_type:{
              vector: true,
              raster: true,
            },
            tag_cathegory: ['年賀状', '虎', '親子'],
          },
          {
            title:'年賀状 寅年 2022 わんぱくな虎の三兄弟のイラスト',
            img_url:'/img/min/250px_nenga2022_22.png',
            caption: '子虎の可愛らしさを描きつつシンプルに仕上げてみました',
            adb_url:'https://stock.adobe.com/jp/images/2022/429722666?prev_url=detail',
            px_url: common_px_url,
            ss_url: common_ss_url,
            imgm_url: common_imgm_url,
            istk_url: common_istk_url,
            tag_type:{
              vector: true,
              raster: true,
            },
            tag_cathegory: ['年賀状', '虎', ''],
          },
          {
            title:'晴れやかな虎と初日の出の年賀状イラスト',
            img_url:'/img/min/250px_nenga2021_604_1.png',
            caption: 'ポスターとしての使用も意識して描きました。',
            adb_url:'https://stock.adobe.com/jp/images/2022/437626594?prev_url=detail',
            px_url: common_px_url,
            ss_url: common_ss_url,
            imgm_url: common_imgm_url,
            istk_url: common_istk_url,
            tag_type:{
              vector: true,
              raster: true,
            },
            tag_cathegory: ['年賀状', '虎', 'シンプル'],
          },
          {
            title:'お正月のモチーフと赤べこ',
            img_url:'/img/min/250px_nenga2021_10.png',
            caption: '年賀状・正月販促用に描いてみました。この年の一番人気です',
            adb_url:'https://stock.adobe.com/jp/images/2021/371160821?prev_url=detail',
            px_url: common_px_url,
            ss_url: common_ss_url,
            imgm_url: common_imgm_url,
            istk_url: common_istk_url,
            tag_type:{
              vector: true,
              raster: true,
            },
            tag_cathegory: ['年賀状', '牛', ''],
          },
          {
            title:'水彩風の牛のイラスト',
            img_url:'/img/min/250px_nenga2020_1_2.png',
            caption: '牛の可愛さと自然体な雰囲気をだしてみました',
            adb_url:'https://stock.adobe.com/jp/images/%E6%B0%B4%E5%BD%A9%E9%A2%A8%E3%82%BF%E3%83%83%E3%83%81%E3%81%AE%E3%83%8A%E3%83%81%E3%83%A5%E3%83%A9%E3%83%AB%E3%81%AA%E4%B9%B3%E7%89%9B%E3%82%A4%E3%83%A9%E3%82%B9%E3%83%88/361793752?prev_url=detail',
            px_url: common_px_url,
            ss_url: common_ss_url,
            imgm_url: common_imgm_url,
            istk_url: common_istk_url,
            tag_type:{
              vector: true,
              raster: true,
            },
            tag_cathegory: ['牛', '動物', '水彩'],
          },
          {
            title:'夏休み 自然の中で昆虫採集する子供たちのベクターイラスト',
            img_url:'/img/min/250px_natsuyasumi03.png',
            caption: '温かみのある色合いにしました',
            adb_url:'https://stock.adobe.com/jp/images/%E8%87%AA%E7%84%B6%E3%81%AE%E4%B8%AD%E3%81%A7%E6%98%86%E8%99%AB%E6%8E%A1%E9%9B%86%E3%81%99%E3%82%8B%E5%AD%90%E4%BE%9B%E3%81%9F%E3%81%A1%E3%81%AE%E3%83%99%E3%82%AF%E3%82%BF%E3%83%BC%E3%82%A4%E3%83%A9%E3%82%B9%E3%83%88/428332747?prev_url=detail',
            px_url: common_px_url,
            ss_url: common_ss_url,
            imgm_url: common_imgm_url,
            istk_url: common_istk_url,
            tag_type:{
              vector: true,
              raster: true,
            },
            tag_cathegory: ['夏', 'こども', '景色'],
          },
          {
            title:'教室で授業を受けるこどもたち',
            img_url:'/img/min/250px_children02_desk.png',
            caption: '楽しくはきはきとした感じをめざしました',
            adb_url:'https://stock.adobe.com/jp/images/%E6%9C%BA%E3%81%AB%E5%BA%A7%E3%82%8B%E7%94%9F%E5%BE%92%E3%81%9F%E3%81%A1%E3%81%AE%E8%A1%A8%E6%83%85%E3%82%A4%E3%83%A9%E3%82%B9%E3%83%88%E3%83%90%E3%83%AA%E3%82%A8%E3%83%BC%E3%82%B7%E3%83%A7%E3%83%B3-%E5%90%B9%E3%81%8D%E5%87%BA%E3%81%97%E4%BB%98%E3%81%8D-%E3%83%99%E3%82%AF%E3%82%BF%E3%83%BC%E3%82%A4%E3%83%A9%E3%82%B9%E3%83%88/426605053?prev_url=detail',
            px_url: common_px_url,
            ss_url: common_ss_url,
            imgm_url: common_imgm_url,
            istk_url: common_istk_url,
            tag_type:{
              vector: true,
              raster: true,
            },
            tag_cathegory: ['勉強', 'こども', '読書'],
          },
          {
            title:'身近な野鳥のイラストカット集',
            img_url:'/img/min/250px_birds_groupe01.png',
            caption: 'スズメ、シジュウカラなど日本でよく見る鳥たち。バラもあります。',
            adb_url:'https://stock.adobe.com/jp/images/%E3%83%A1%E3%82%B8%E3%83%AD%E3%81%8C%E9%A3%9B%E3%81%B6%E7%A7%8B%E3%81%AE%E3%83%A2%E3%83%81%E3%83%BC%E3%83%95%E3%82%92%E6%95%A3%E3%82%8A%E3%81%B0%E3%82%81%E3%81%9F%E5%86%86%E5%BD%A2%E3%83%99%E3%82%AF%E3%82%BF%E3%83%BC%E3%82%A4%E3%83%A9%E3%82%B9%E3%83%88/372705244?prev_url=detail',
            px_url: common_px_url,
            ss_url: '',
            imgm_url: '',
            istk_url: '',
            tag_type:{
              vector: true,
              raster: true,
            },
            tag_cathegory: ['鳥', 'セット', 'シンプル'],
          },
          {
            title:'水彩風の木のイラスト',
            img_url:'/img/min/250px_tree04.png',
            caption: '水彩の柔らかい風合いで自然を表現しました',
            adb_url:'https://stock.adobe.com/jp/images/%E6%96%B0%E7%B7%91%E3%81%AE%E6%9C%A8-%E3%82%BD%E3%83%95%E3%83%88%E3%81%A7%E6%B0%B4%E5%BD%A9%E9%A2%A8%E3%81%AB%E6%8F%8F%E3%81%84%E3%81%9F%E3%82%A4%E3%83%A9%E3%82%B9%E3%83%88/448666949?prev_url=detail',
            px_url: common_px_url,
            ss_url: common_ss_url,
            imgm_url: common_imgm_url,
            istk_url: common_istk_url,
            tag_type:{
              vector: true,
              raster: true,
            },
            tag_cathegory: ['木', '自然', '水彩'],
          },
          {
            title:'Webデザインチームワーク',
            img_url:'/img/min/250px_tech_website01_how.png',
            caption: 'お客さまとじっくり話しながらよりよいサイトを作ります',
            adb_url:'https://stock.adobe.com/jp/images/web/443012077?prev_url=detail',
            px_url: common_px_url,
            ss_url: common_ss_url,
            imgm_url: common_imgm_url,
            istk_url: common_istk_url,
            tag_type:{
              vector: true,
              raster: true,
            },
            tag_cathegory: ['WEB', '仕事', '人'],
          },
          {
            title:'夏野菜と青い空',
            img_url:'/img/min/250px_summer_vegetable_bg_01.png',
            caption: '野菜のイラストたくさんご用意してます。各サイトで検索してみてください',
            adb_url:'https://stock.adobe.com/jp/images/%E8%B1%8A%E3%81%8B%E3%81%AA%E8%87%AA%E7%84%B6%E3%81%AB%E8%82%B2%E3%81%BE%E3%82%8C%E3%81%9F%E3%83%88%E3%83%9E%E3%83%88%E3%82%84%E3%83%8A%E3%82%B9%E3%81%AA%E3%81%A9%E5%A4%8F%E9%87%8E%E8%8F%9C%E3%81%AE%E3%82%A4%E3%83%A1%E3%83%BC%E3%82%B8%E3%82%A4%E3%83%A9%E3%82%B9%E3%83%88-%E3%83%99%E3%82%AF%E3%82%BF%E3%83%BC/432748140?prev_url=detail',
            px_url: common_px_url,
            ss_url: common_ss_url,
            imgm_url: common_imgm_url,
            istk_url: common_istk_url,
            tag_type:{
              vector: true,
              raster: true,
            },
            tag_cathegory: ['野菜', '植物', '夏'],
          },
          {
            title:'かわいいサンタクロース',
            img_url:'/img/min/250px_santa_02.png',
            caption: '子供向けの媒体にどうぞ！',
            adb_url:'https://stock.adobe.com/jp/images/%E3%81%8B%E3%82%8F%E3%81%84%E3%81%84%E3%82%B5%E3%83%B3%E3%82%BF%E3%82%AF%E3%83%AD%E3%83%BC%E3%82%B9%E3%81%8C%E6%89%8B%E3%82%92%E6%8C%AF%E3%82%8B%E3%83%99%E3%82%AF%E3%82%BF%E3%83%BC%E3%82%A4%E3%83%A9%E3%82%B9%E3%83%88/460399355?prev_url=detail',
            px_url: common_px_url,
            ss_url: common_ss_url,
            imgm_url: common_imgm_url,
            istk_url: common_istk_url,
            tag_type:{
              vector: true,
              raster: true,
            },
            tag_cathegory: ['クリスマス', '人', 'こども'],
          },
          {
            title:'新米のごはん',
            img_url:'/img/min/250px_shinmai_06_gohan.png',
            caption: '美味しそうに見えつつシンプルに。',
            adb_url:'https://stock.adobe.com/jp/search?creator_id=207860720&filters%5Bcontent_type%3Aphoto%5D=1&filters%5Bcontent_type%3Aillustration%5D=1&filters%5Bcontent_type%3Azip_vector%5D=1&filters%5Bcontent_type%3Avideo%5D=1&filters%5Bcontent_type%3Atemplate%5D=1&filters%5Bcontent_type%3A3d%5D=1&filters%5Bis_editorial%5D=all&filters%5Bfetch_excluded_assets%5D=1&filters%5Bcontent_type%3Aimage%5D=1&order=relevance&safe_search=1&limit=100&search_page=1&search_type=usertyped&acp=&aco=%E7%B1%B3&k=%E7%B1%B3&get_facets=0&asset_id=450612398',
            px_url: common_px_url,
            ss_url: common_ss_url,
            imgm_url: common_imgm_url,
            istk_url: common_istk_url,
            tag_type:{
              vector: true,
              raster: true,
            },
            tag_cathegory: ['食べ物', '植物', ''],
          },
          {
            title:'春の山 桜とシジュウカラ',
            img_url:'/img/min/250px_spring_mountain02.png',
            caption: '春のアウトドアイベントに。お気に入りの絵です。',
            adb_url:'https://stock.adobe.com/jp/images/1-4/426316521?prev_url=detail',
            px_url: common_px_url,
            ss_url: common_ss_url,
            imgm_url: common_imgm_url,
            istk_url: common_istk_url,
            tag_type:{
              vector: true,
              raster: true,
            },
            tag_cathegory: ['鳥', '春', '景色'],
          },
          {
            title:'Adobeストック専売）ステイホームイラスト',
            img_url:'/img/min/250px_Stayhome.png',
            caption: 'コロナが流行り始めた頃に書きました。',
            adb_url:'https://stock.adobe.com/jp/images/enjoy-stay-home-planning-holiday-schedule/346344854?prev_url=detail',
            px_url: '',
            ss_url: '',
            imgm_url: '',
            istk_url: '',
            tag_type:{
              vector: true,
              raster: true,
            },
            tag_cathegory: ['出来事', 'コロナ', ''],
          },
          {
            title:'朝顔の鉢いろいろ',
            img_url:'/img/min/250px_asagao_flow01_4.png',
            caption: '成長過程のイラストもあります',
            adb_url:'https://stock.adobe.com/jp/images/1-4/426316521?prev_url=detail',
            px_url: common_px_url,
            ss_url: common_ss_url,
            imgm_url: common_imgm_url,
            istk_url: common_istk_url,
            tag_type:{
              vector: true,
              raster: true,
            },
            tag_cathegory: ['植物', '夏', '花'],
          },
          {
            title:'四季の季節に野鳥のイラスト',
            img_url:'/img/min/250px_birds_forest_season_as.png',
            caption: 'よく見ると野鳥があちらこちらに',
            adb_url:'https://stock.adobe.com/jp/images/%E9%B3%A5%E3%81%9F%E3%81%A1%E3%81%AE%E3%81%84%E3%82%8B%E8%87%AA%E7%84%B6%E3%81%AE%E9%A2%A8%E6%99%AF,-%E5%B9%BE%E4%BD%95%E5%AD%A6%E3%83%95%E3%83%A9%E3%83%83%E3%83%88%E3%81%AA%E3%83%99%E3%82%AF%E3%82%BF%E3%83%BC%E3%82%A4%E3%83%A9%E3%82%B9%E3%83%88,-%E5%AD%A3%E7%AF%80%E3%82%B7%E3%83%AA%E3%83%BC%E3%82%BA%E3%81%AE%E3%82%BB%E3%83%83%E3%83%88/354206136?prev_url=detail',
            px_url: common_px_url,
            ss_url: common_ss_url,
            imgm_url: common_imgm_url,
            istk_url: common_istk_url,
            tag_type:{
              vector: true,
              raster: true,
            },
            tag_cathegory: ['四季', '景色', '鳥'],
          },
          {
            title:'子供の体の不調イラスト（男の子）',
            img_url:'/img/min/250px_children04_health_ss.png',
            caption: '女の子バージョンもあります',
            adb_url:'https://stock.adobe.com/jp/images/%E3%83%A1%E3%82%B8%E3%83%AD%E3%81%8C%E9%A3%9B%E3%81%B6%E7%A7%8B%E3%81%AE%E3%83%A2%E3%83%81%E3%83%BC%E3%83%95%E3%82%92%E6%95%A3%E3%82%8A%E3%81%B0%E3%82%81%E3%81%9F%E5%86%86%E5%BD%A2%E3%83%99%E3%82%AF%E3%82%BF%E3%83%BC%E3%82%A4%E3%83%A9%E3%82%B9%E3%83%88/372705244?prev_url=detail',
            px_url: '',
            ss_url: common_ss_url,
            imgm_url: common_imgm_url,
            istk_url: common_istk_url,
            tag_type:{
              vector: true,
              raster: true,
            },
            tag_cathegory: ['人物', '子供', '健康'],
          },
          {
            title:'クリスマスリース',
            img_url:'/img/min/250px_christmas_wreath02_square.png',
            caption: 'クリスマスツリー、オーナメントなどクリスマス素材各種あります',
            adb_url:'https://stock.adobe.com/jp/images/%E6%98%8E%E3%82%8B%E3%81%84%E8%89%B2%E5%90%88%E3%81%84%E3%81%AE%E3%82%AA%E3%83%BC%E3%83%8A%E3%83%A1%E3%83%B3%E3%83%88%E3%81%A8%E6%9F%8A%E3%81%AE%E3%82%AF%E3%83%AA%E3%82%B9%E3%83%9E%E3%82%B9%E3%83%AA%E3%83%BC%E3%82%B9%E3%81%AE%E3%83%99%E3%82%AF%E3%82%BF%E3%83%BC%E3%82%A4%E3%83%A9%E3%82%B9%E3%83%88/368897467?prev_url=detail',
            px_url: common_px_url,
            ss_url: common_ss_url,
            imgm_url: common_imgm_url,
            istk_url: common_istk_url,
            tag_type:{
              vector: true,
              raster: true,
            },
            tag_cathegory: ['クリスマス', '冬', '植物'],
          },
          {
            title:'とうもろこしのイラスト',
            img_url:'/img/min/250px_corn02.png',
            caption: 'とうもろこしだけでも数パターン、他の野菜もあります。',
            adb_url:'https://stock.adobe.com/jp/images/%E3%83%A1%E3%82%B8%E3%83%AD%E3%81%8C%E9%A3%9B%E3%81%B6%E7%A7%8B%E3%81%AE%E3%83%A2%E3%83%81%E3%83%BC%E3%83%95%E3%82%92%E6%95%A3%E3%82%8A%E3%81%B0%E3%82%81%E3%81%9F%E5%86%86%E5%BD%A2%E3%83%99%E3%82%AF%E3%82%BF%E3%83%BC%E3%82%A4%E3%83%A9%E3%82%B9%E3%83%88/372705244?prev_url=detail',
            px_url: common_px_url,
            ss_url: common_ss_url,
            imgm_url: common_imgm_url,
            istk_url: common_istk_url,
            tag_type:{
              vector: true,
              raster: true,
            },
            tag_cathegory: ['野菜', '植物', '夏'],
          },
          {
            title:'カレーとその材料',
            img_url:'/img/min/250px_curry_pot02.png',
            caption: 'カレー野菜とカレー鍋をセットにしました',
            adb_url:'https://stock.adobe.com/jp/images/%E7%BE%8E%E5%91%B3%E3%81%97%E3%81%84%E3%82%AB%E3%83%AC%E3%83%BC%E3%81%AE%E9%8D%8B%E3%81%A8%E3%80%81%E3%82%AB%E3%83%AC%E3%83%BC%E3%81%AE%E6%9D%90%E6%96%99%E3%81%AE%E3%83%99%E3%82%AF%E3%82%BF%E3%83%BC%E3%82%A4%E3%83%A9%E3%82%B9%E3%83%88/437196230?prev_url=detail',
            px_url: common_px_url,
            ss_url: common_ss_url,
            imgm_url: common_imgm_url,
            istk_url: common_istk_url,
            tag_type:{
              vector: true,
              raster: true,
            },
            tag_cathegory: ['野菜', '植物', '料理'],
          },
          {
            title:'花弁散る桜の枝',
            img_url:'/img/min/250px_flower02_fin_px.png',
            caption: '桜の枝は他のバリエーションもあります',
            adb_url:'https://stock.adobe.com/jp/stock-photo/id/322906417',
            px_url: common_px_url,
            ss_url: common_ss_url,
            imgm_url: common_imgm_url,
            istk_url: common_istk_url,
            tag_type:{
              vector: true,
              raster: true,
            },
            tag_cathegory: ['春', '植物', '花'],
          },
          {
            title:'ハロウィン仮装のイラスト',
            img_url:'/img/min/250px_halloween_01.png',
            caption: 'ハロウィン仮装のイラストバリエーションあります',
            adb_url:'https://stock.adobe.com/jp/stock-photo/id/437209942',
            px_url: common_px_url,
            ss_url: common_ss_url,
            imgm_url: common_imgm_url,
            istk_url: common_istk_url,
            tag_type:{
              vector: true,
              raster: true,
            },
            tag_cathegory: ['秋', '人物', '子供'],
          },
          {
            title:'メジロと秋の自然',
            img_url:'/img/min/250px_Autumn_circle_square.png',
            caption: 'かわいい秋のモチーフカット',
            adb_url:'https://stock.adobe.com/jp/images/%E3%83%A1%E3%82%B8%E3%83%AD%E3%81%8C%E9%A3%9B%E3%81%B6%E7%A7%8B%E3%81%AE%E3%83%A2%E3%83%81%E3%83%BC%E3%83%95%E3%82%92%E6%95%A3%E3%82%8A%E3%81%B0%E3%82%81%E3%81%9F%E5%86%86%E5%BD%A2%E3%83%99%E3%82%AF%E3%82%BF%E3%83%BC%E3%82%A4%E3%83%A9%E3%82%B9%E3%83%88/372705244?prev_url=detail',
            px_url: common_px_url,
            ss_url: common_ss_url,
            imgm_url: common_imgm_url,
            istk_url: common_istk_url,
            tag_type:{
              vector: true,
              raster: true,
            },
            tag_cathegory: ['鳥', '紅葉', '秋'],
          },
          {
            title:'かわいい鳥のシンプルなジオメトリックアイコンセット',
            img_url:'/img/min/250px_birds00_ol_notext_rf.png',
            caption: '日本の鳥、アメリカの鳥、フクロウ、ペンギンなどシリーズがあります',
            adb_url:'https://stock.adobe.com/jp/images/%E5%B0%8F%E3%81%95%E3%81%AA%E9%B3%A5%E3%81%9F%E3%81%A1%E3%81%AE%E3%82%B7%E3%83%B3%E3%83%97%E3%83%AB%E3%81%A7%E3%82%AB%E3%83%A9%E3%83%95%E3%83%AB%E3%81%AA%E3%82%A2%E3%82%A4%E3%82%B3%E3%83%B3/216344104?prev_url=detail',
            px_url: common_px_url,
            ss_url: common_ss_url,
            imgm_url: common_imgm_url,
            istk_url: common_istk_url,
            tag_type:{
              vector: true,
              raster: true,
            },
            tag_cathegory: ['鳥', 'アイコン', 'セット'],
          },
          {
            title:'秋のフルーツ集合イメージ',
            img_url:'/img/min/250px_fruitsAutumn_group_af.png',
            caption: 'りんごやブドウ、クリなど定番の秋の果物。バラ売りもあります。',
            adb_url:'https://stock.adobe.com/jp/images/fruits-autumn-vector-illustration-on-white-background/351090944?prev_url=detail',
            px_url: common_px_url,
            ss_url: common_ss_url,
            imgm_url: common_imgm_url,
            istk_url: "",
            tag_type:{
              vector: true,
              raster: true,
            },
            tag_cathegory: ['果物', '水彩風', '秋'],
          },
          {
            title:'たくさんの本のイラスト バリエーション',
            img_url:'/img/min/250px_isome_book01_3_tower_ss.png',
            caption: '並べられた本、積まれた本、散らかっている本。本のイラストシリーズ他にもあります。',
            adb_url:'https://stock.adobe.com/jp/images/fruits-autumn-vector-illustration-on-white-background/351090944?prev_url=detail',
            px_url: common_px_url,
            ss_url: common_ss_url,
            imgm_url: common_imgm_url,
            istk_url: common_istk_url,
            tag_type:{
              vector: true,
              raster: true,
            },
            tag_cathegory: ['本', '読書', '積読'],
          },
          {
            title:'世界とこどもたち',
            img_url:'/img/min/250px_isome_children_world02.png',
            caption: 'アイソメトリック図法で描いた世界の子供たちのイラスト',
            adb_url:'https://stock.adobe.com/jp/images/%E3%82%A2%E3%82%A4%E3%82%BD%E3%83%A1%E3%83%88%E3%83%AA%E3%83%83%E3%82%AF-%E6%A7%98%E3%80%85%E3%81%AA%E4%BA%BA%E7%A8%AE%E3%81%AE%E4%B8%96%E7%95%8C%E3%81%AE%E5%AD%90%E3%81%A9%E3%82%82%E3%81%9F%E3%81%A1%E3%81%8C%E4%B8%96%E7%95%8C%E5%9C%B0%E5%9B%B3%E3%81%AE%E4%B8%8A%E3%81%A7%E4%BB%B2%E8%89%AF%E3%81%8F%E9%81%8A%E3%81%B6%E3%82%AB%E3%83%A9%E3%83%95%E3%83%AB%E3%81%AA%E3%83%99%E3%82%AF%E3%82%BF%E3%83%BC%E3%82%A4%E3%83%A9%E3%82%B9%E3%83%88/385963950?prev_url=detail',
            px_url: common_px_url,
            ss_url: common_ss_url,
            istk_url: "",
            imgm_url: common_imgm_url,
            tag_type:{
              vector: true,
              raster: true,
            },
            tag_cathegory: ['本', '読書', '積読'],
          },
          {
            title:'読書家と秋と本のイラスト',
            img_url:'/img/min/250px_isome_reader02.png',
            caption: 'アイソメトリック風、読書の秋',
            adb_url:'https://stock.adobe.com/jp/images/%E8%AA%AD%E6%9B%B8%E3%81%AE%E7%A7%8B%E3%81%AB%E3%83%AA%E3%83%A9%E3%83%83%E3%82%AF%E3%82%B9%E3%81%97%E3%81%A6%E8%AA%AD%E6%9B%B8%E3%81%99%E3%82%8B%E5%A5%B3%E6%80%A7%E3%81%AE%E3%82%A2%E3%82%A4%E3%82%BD%E3%83%A1%E3%83%88%E3%83%AA%E3%83%83%E3%82%AF%E3%81%AA%E3%83%99%E3%82%AF%E3%82%BF%E3%83%BC%E3%82%A4%E3%83%A9%E3%82%B9%E3%83%88/367081741?prev_url=detail',
            px_url: "",
            ss_url: common_ss_url,
            istk_url: common_istk_url,
            imgm_url: common_imgm_url,
            tag_type:{
              vector: true,
              raster: true,
            },
            tag_cathegory: ['女性', '読書', '秋'],
          },
          {
            title:'チャットツールでやり取り',
            img_url:'/img/min/250px_isome_tech5.png',
            caption: 'リモートワーク中のやりとりのイメージイラスト。',
            adb_url:'https://stock.adobe.com/jp/images/2/383400946?prev_url=detail',
            px_url: common_px_url,
            ss_url: common_ss_url,
            istk_url: common_istk_url,
            imgm_url: common_imgm_url,
            tag_type:{
              vector: true,
              raster: true,
            },
            tag_cathegory: ['人物', '仕事', 'PC'],
          },
          {
            title:'はばたくシジュウカラ',
            img_url:'/img/min/250px_sijukara1_3.png',
            caption: '鳥のベクターイラスト、複数ポーズでシリーズ制作しています',
            adb_url:'https://stock.adobe.com/jp/images/%E7%BE%BD%E3%81%B0%E3%81%9F%E3%81%8F%E3%81%8B%E3%82%8F%E3%81%84%E3%81%84%E3%82%B7%E3%82%B8%E3%83%A5%E3%82%A6%E3%82%AB%E3%83%A9%E3%81%AE%E3%83%99%E3%82%AF%E3%82%BF%E3%83%BC%E3%82%A4%E3%83%A9%E3%82%B9%E3%83%88/378766288?prev_url=detail',
            px_url: common_px_url,
            ss_url: common_ss_url,
            istk_url: common_istk_url,
            imgm_url: common_imgm_url,
            tag_type:{
              vector: true,
              raster: true,
            },
            tag_cathegory: ['鳥', ],
          },
        ]
      }
    },
    computed: {
      numberOfPages () {
        return Math.ceil(this.imageCardLists.length / this.itemsPerPage)
      },
      filteredKeys () {
        return this.keys.filter(key => key !== 'title')
      },
    },
    methods: {
      nextPage () {
        if (this.page + 1 <= this.numberOfPages) this.page += 1
      },
      formerPage () {
        if (this.page - 1 >= 1) this.page -= 1
      },
      updateItemsPerPage (number) {
        this.itemsPerPage = number
      },
    },
  }
</script>

<style scoped>
</style>
