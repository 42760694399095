<template>
  <v-main>
    <v-container fluid >

      <v-container fluid class="bl_headline">
        <v-row justify="center">
            <h4 class="text-h4">こんなお仕事をしています</h4>
        </v-row>
        <v-row justify="center">
            <router-link to="works/detail" class="el_btn primary white--text hp_updated">お仕事実績例</router-link>
        </v-row>

        <v-row justify="center" class="bl_headline_lists">
          <v-col md="3" sm="9" cols="12">
            <h4>webデザイン<br />コーディング</h4>
            <ul>
              <li>業務用計算プログラム</li>
              <li>イベント告知サイト</li>
              <li>企業webサイト</li>
            </ul>
          </v-col>
          <v-col md="3" sm="9" cols="12">
            <h4>紙媒体デザイン</h4>
            <ul>
              <li>集客チラシ・冊子</li>
              <li>名刺・パンフレット</li>
            </ul>
          </v-col>
          <v-col md="3" sm="9" cols="12">
            <h4>ロゴデザイン<br />イラスト制作</h4>
            <ul>
              <li>キャラクターイラスト</li>
              <li>紙・web媒体用イラストカット</li>
              <li>ストックイラスト（下記参照）</li>
            </ul>
          </v-col>
          <v-col md="3" sm="9" cols="12">
            <h4>その他</h4>
            <ul>
              <li>ワークショップ開催</li>
              <li>イベント運営サポート</li>
              <li>看板デザイン制作</li>
              <li>オリジナルグッズ制作</li>
            </ul>
          </v-col>
        </v-row>
      </v-container>

      <v-container class="bl_headline">
        <v-row justify="center">
            <h4 class="text-h4">素材サイトに登録中のイラスト紹介</h4>
        </v-row>

        <v-row justify="center" >
          <span>複数のサイトでイラスト素材を販売しています。moreをクリックしてそれぞれのサイトに飛べます。<br />
          ここにあるイラスト以外もふくめて、2023年4月現在各サイト800~1100点を販売中です。</span>
        </v-row>
      </v-container>

      <v-container>
        <works-stockillust />
      </v-container>

    </v-container>
  </v-main>
</template>

<script>
import WorksStockillust from '../components/organisms/WorksStockillust.vue'

  export default {
    name: 'works',
    components: {
        WorksStockillust

    },
    data(){
      return{

      }
    }
  }
</script>

<style scoped>
.bl_container__default{
  display: flex;
  column-gap: 16px;
  row-gap: 32px;
  margin: 16px auto;
  padding: 0 16px;
}
.bl_cardListWrapper{
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  max-width: 100vw;
  column-gap: 16px;
  row-gap: 32px;
}
.bl_headline_lists>ul{
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  column-gap: 32px;
  list-style-type: none;
}
.bl_headline_lists {
  display: flex;
  justify-content: center;
  padding: 16px 24px;
  background-color: #efefef;
}
.bl_headline_lists h4{
  text-align: center;
}
.bl_headline_lists ul{
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 4px;
  margin-top: 8px;
  padding-left: 0;
  list-style-type: none;
}
</style>
